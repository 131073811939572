

<template>
  <div
    class="product-in-order"
    :class="{selectedProduct: clickedAmount > 0}"
    @click="addProductToSelected()"
  >
    <div style="margin-bottom: 10px;" v-if="!tableOpening.currentlyOpen">
      <div
        class="product-with-payed-details-container"
        v-if="orderContent.quantity==orderContent.quantityCanceled"
      >
        <div class="product-header-container">
          <div class="product-header">
            <span class="quantity-canceled-number">{{orderContent.quantityCanceled}}</span>
            <h6 class="product-name">{{orderContent.product.names.translation[language].name}}</h6>
            <h6
              class="price-of-product"
            >{{ getPriceForProductWithSubselections(orderContent, orderContent.quantityCanceled).toFixed(2) }}€</h6>
          </div>
        </div>
        <div class="product-header-container">
          <div class="product-header-canceled">
            <h6 class="product-name-canceled">
              <font-awesome-icon style="margin-bottom: 2px; margin-right: 1px;" icon="trash-alt" />
              {{orderContent.quantityCanceled == 1 ? labels.tableOpenings.canceledProduct[language] : labels.tableOpenings.canceledProducts[language]}}
            </h6>
          </div>
        </div>
      </div>

      <div
        class="product-with-payed-details-container"
        v-else-if="orderContent.quantity==orderContent.quantityTreated"
      >
        <div class="product-header-container">
          <div class="product-header">
            <span class="quantity-treated-number">{{orderContent.quantityTreated}}</span>
            <h6 class="product-name">{{orderContent.product.names.translation[language].name}}</h6>
            <h6
              class="price-of-product"
            >{{ getPriceForProductWithSubselections(orderContent, orderContent.quantityTreated).toFixed(2) }}€</h6>
          </div>
        </div>
        <div class="product-header-container">
          <div class="product-header-treated">
            <h6 class="product-name-treated">
              <font-awesome-icon style="margin-bottom: 2px; margin-right: 1px;" icon="gift" />
              {{orderContent.quantityTreated == 1 ? labels.tableOpenings.treatedProduct[language] : labels.tableOpenings.treatedProducts[language]}}
            </h6>
          </div>
        </div>
      </div>

      <div class="product-with-payed-details-container" v-else>
        <div class="product-header-container">
          <div class="product-header">
            <span class="quantity-number">{{orderContent.quantity}}</span>
            <h6 class="product-name">{{orderContent.product.names.translation[language].name}}</h6>
            <h6
              class="price-of-product"
            >{{ getPriceForProductWithSubselections(orderContent, orderContent.quantity).toFixed(2) }}€</h6>
          </div>
        </div>
      </div>

      <SubselectionsOfProductAtOrder
        :language="language"
        :productSubselections="orderContent.productSubselections"
      ></SubselectionsOfProductAtOrder>
      <div
        class="product-comment-container"
        v-if="orderContent.comments!=null && orderContent.comments.trim().length!=0"
      >
        <h6 class="comment-of-product">
          <span class="comment-title">{{ labels.cart.comments[language] }}</span>
          {{orderContent.comments}}
        </h6>
      </div>
    </div>
    <div
      style="margin-bottom: 10px;"
      v-else-if="hideDetails && (orderContent.quantity - orderContent.quantityPayedBeforeClose - orderContent.quantityCanceled- orderContent.quantityChargedToRoom- orderContent.quantityTreated)>0"
    >
      <div class="product-header-container">
        <div class="product-header">
          <span
            class="quantity-number"
          >{{orderContent.quantity - orderContent.quantityPayedBeforeClose - orderContent.quantityCanceled - orderContent.quantityChargedToRoom- orderContent.quantityTreated}}</span>
          <h6 class="product-name">{{orderContent.product.names.translation[language].name}}</h6>
          <h6
            class="price-of-product"
          >{{ getPriceForProductWithSubselections(orderContent, orderContent.quantity - orderContent.quantityPayedBeforeClose - orderContent.quantityCanceled- orderContent.quantityChargedToRoom- orderContent.quantityTreated).toFixed(2) }}€</h6>
        </div>
        <div
          v-if="clickedAmount > 0"
          class="selected-number-of-product"
        >{{clickedAmount}}/{{orderContent.quantity - orderContent.quantityPayedBeforeClose - orderContent.quantityCanceled- orderContent.quantityChargedToRoom- orderContent.quantityTreated}}</div>
      </div>

      <SubselectionsOfProductAtOrder
        :language="language"
        :productSubselections="orderContent.productSubselections"
      ></SubselectionsOfProductAtOrder>
      <div
        class="product-comment-container"
        v-if="orderContent.comments!=null && orderContent.comments.trim().length!=0"
      >
        <h6 class="comment-of-product">
          <span class="comment-title">{{ labels.cart.comments[language] }}</span>
          {{orderContent.comments}}
        </h6>
      </div>
    </div>
    <div style="margin-bottom: 10px;" v-else-if="!hideDetails">
      <div
        class="product-with-payed-details-container"
        v-if="orderContent.quantity==orderContent.quantityCanceled"
      >
        <div class="product-header-container">
          <div class="product-header">
            <span class="quantity-canceled-number">{{orderContent.quantityCanceled}}</span>
            <h6 class="product-name">{{orderContent.product.names.translation[language].name}}</h6>
            <h6
              class="price-of-product"
            >{{ getPriceForProductWithSubselections(orderContent, orderContent.quantityCanceled).toFixed(2) }}€</h6>
          </div>
        </div>
        <div class="product-header-container">
          <div class="product-header-canceled">
            <h6 class="product-name-canceled">
              <font-awesome-icon style="margin-bottom: 2px; margin-right: 1px;" icon="trash-alt" />
              {{orderContent.quantityCanceled == 1 ? labels.tableOpenings.canceledProduct[language] : labels.tableOpenings.canceledProducts[language]}}
            </h6>
          </div>
        </div>
      </div>

      <div
        class="product-with-payed-details-container"
        v-else-if="orderContent.quantity==orderContent.quantityTreated"
      >
        <div class="product-header-container">
          <div class="product-header">
            <span class="quantity-treated-number">{{orderContent.quantityTreated}}</span>
            <h6 class="product-name">{{orderContent.product.names.translation[language].name}}</h6>
            <h6
              class="price-of-product"
            >{{ getPriceForProductWithSubselections(orderContent, orderContent.quantityTreated).toFixed(2) }}€</h6>
          </div>
        </div>
        <div class="product-header-container">
          <div class="product-header-treated">
            <h6 class="product-name-treated">
              <font-awesome-icon style="margin-bottom: 2px; margin-right: 1px;" icon="gift" />
              {{orderContent.quantityTreated == 1 ? labels.tableOpenings.treatedProduct[language] : labels.tableOpenings.treatedProducts[language]}}
            </h6>
          </div>
        </div>
      </div>

      <div class="product-with-payed-details-container" v-else>
        <div class="product-header-container">
          <div class="product-header">
            <span class="quantity-number">{{orderContent.quantity}}</span>
            <h6 class="product-name">{{orderContent.product.names.translation[language].name}}</h6>
            <h6
              class="price-of-product"
            >{{ getPriceForProductWithSubselections(orderContent, orderContent.quantity).toFixed(2) }}€</h6>
          </div>
          <div
            v-if="clickedAmount > 0"
            class="selected-number-of-product"
          >{{clickedAmount}}/{{orderContent.quantity-orderContent.quantityPayedBeforeClose -orderContent.quantityChargedToRoom}}</div>
        </div>
        <div class="product-header-container" v-if="orderContent.quantityPayedBeforeClose>0">
          <div class="product-header-already-paid">
            <h6 class="product-name-already-paid">
              <font-awesome-icon
                style="margin-bottom: 2px; margin-right: 1px;"
                icon="cash-register"
              />
              {{orderContent.quantityPayedBeforeClose==1 ? labels.tableOpenings.paidAlreadyOne[language] : labels.tableOpenings.paidAlreadyPlural[language]}} {{orderContent.quantityPayedBeforeClose}} |
            </h6>
            <h6
              class="price-of-product-already-paid"
            >{{ getPriceForProductWithSubselections(orderContent, orderContent.quantityPayedBeforeClose).toFixed(2) }}€</h6>
          </div>
        </div>
        <div class="product-header-container" v-if="orderContent.quantityChargedToRoom>0">
          <div class="product-header-already-paid">
            <h6 class="product-name-already-paid">
              <font-awesome-icon style="margin-bottom: 2px; margin-right: 1px;" icon="bed" />
              {{orderContent.quantityChargedToRoom==1 ? labels.tableOpenings.chargedToRoomOne[language] : labels.tableOpenings.chargedToRoom[language]}} {{orderContent.quantityChargedToRoom}} |
            </h6>
            <h6
              class="price-of-product-already-paid"
            >{{ getPriceForProductWithSubselections(orderContent, orderContent.quantityChargedToRoom).toFixed(2) }}€</h6>
          </div>
        </div>
      </div>

      <SubselectionsOfProductAtOrder
        :language="language"
        :productSubselections="orderContent.productSubselections"
      ></SubselectionsOfProductAtOrder>
      <div
        class="product-comment-container"
        v-if="orderContent.comments!=null && orderContent.comments.trim().length!=0"
      >
        <h6 class="comment-of-product">
          <span class="comment-title">{{ labels.cart.comments[language] }}</span>
          {{orderContent.comments}}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import SubselectionsOfProductAtOrder from "./SubselectionsOfProductAtOrder";
export default {
  name: "ProductInOrder",
  components: { SubselectionsOfProductAtOrder },
  data() {
    return {
      clickedAmount: 0
    };
  },
  props: ["orderContent", "language", "hideDetails", "tableOpening"],
  methods: {
    getPriceForProductWithSubselections(orderContent, quantity) {
      let subselectionsTotalSumPrice = 0;
      if (orderContent.productSubselections && orderContent.productSubselections.length > 0) {
        orderContent.productSubselections.forEach(productSubselection => {
          subselectionsTotalSumPrice = subselectionsTotalSumPrice + productSubselection.price;
        });
      }
      return quantity * orderContent.price + quantity * subselectionsTotalSumPrice;
    },
    addProductToSelected() {
      if (this.orderContent.quantity > (this.orderContent.quantityPayedBeforeClose + this.orderContent.quantityCanceled + this.orderContent.quantityChargedToRoom + this.orderContent.quantityTreated) && this.tableOpening.currentlyOpen) {
        if (this.clickedAmount < (this.orderContent.quantity - this.orderContent.quantityPayedBeforeClose - this.orderContent.quantityCanceled - this.orderContent.quantityChargedToRoom - this.orderContent.quantityTreated)) {
          this.clickedAmount = this.clickedAmount + 1
          let priceOfProduct = this.getPriceForProductWithSubselections(this.orderContent, 1);
          let selectedOrderProduct = {
            id: this.orderContent.id,
            quantityToBePaid: this.clickedAmount,
            quantityCanceled: this.clickedAmount,
            quantityToBeTransfered: this.clickedAmount,
            quantityTreated: this.clickedAmount,
            quantityReOrder: this.clickedAmount,
            priceOfProduct: priceOfProduct
          }
          this.$emit("select-order-product", selectedOrderProduct);
        } else {
          this.clickedAmount = 0;
          let selectedOrderProduct = {
            id: this.orderContent.id,
            quantityToBePaid: this.clickedAmount,
            quantityCanceled: this.clickedAmount,
            quantityTreated: this.clickedAmount,
            quantityToBeTransfered: this.clickedAmount,
            quantityReOrder: this.clickedAmount,
            priceOfProduct: 0
          }
          this.$emit("select-order-product", selectedOrderProduct);
        }
      }

    }
  },
  mounted() {

  }
};
</script>

<style scoped>
.product-in-order {
  padding-left: 8px;
}

.selectedProduct {
  background-color: gainsboro;
  background-color: #c5d8f5;
  padding-bottom: 2px;
}

.product-with-payed-details-container {
  display: flex;
  flex-direction: column;
}

.product-header-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  justify-content: space-between;
  align-items: flex-start;
}

.product-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}

.product-header-already-paid {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  align-items: flex-start;
  margin-left: 19px;
  color: #198b4a;
}

.product-header-canceled {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  align-items: flex-start;
  margin-left: 19px;
  color: #ce2020;
}

.product-header-treated {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  align-items: flex-start;
  margin-left: 19px;
  color: #6f2c91;
}

.quantity-number {
  font-size: 17px;
  font-weight: 900;
  margin-right: 8px;
  color: #198b4a;
}

.quantity-canceled-number {
  font-size: 17px;
  font-weight: 900;
  margin-right: 8px;
  color: #ce2020;
}

.quantity-treated-number {
  font-size: 17px;
  font-weight: 900;
  margin-right: 8px;
  color: #6f2c91;
}

.product-name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-right: 6px;
}

.product-name-canceled,
.product-name-treated,
.product-name-already-paid {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-right: 4px;
}

.price-of-product {
  margin-top: 2px;
  font-size: 15px;
  margin-bottom: 0px;
  margin-right: 3px;
}

.price-of-product-already-paid {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.selected-number-of-product {
  font-size: 13px;
  font-weight: 600;
  margin-right: 6px;
  margin-left: 6px;
  margin-top: 2px;
  margin-bottom: 0px;
  color: #353535;
  background-color: rgb(236, 236, 236);
  padding: 0.25rem 0.25rem 0.125rem;
  border-radius: 4px;
}

.product-comment-container {
  padding-left: 19px;
}

.comment-of-product {
  font-size: 14px;
  margin-bottom: 0px;
  color: #393939;
}

.comment-title {
  font-weight: 700;
  margin-right: 6px;
}
</style>