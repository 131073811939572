<template>
  <div
    v-if="tableOpening"
    translate="no"
    @click="onClickOutside"
    id="modal-container"
    class="modal-container-top-screen"
  >
    <div id="modal" class="modal-bigger">
      <div id="header-and-content-container">
        <div id="open-table-modal-header">
          <div id="table-and-close-button">
            <h3
              id="item-name"
            >{{ tableOpening.table.type=='BEACH_CHAIR' || tableOpening.table.type=='UMBRELLA_AT_HOTEL' ? labels.tableOpenings.beachChairHeader[language] : tableOpening.table.type=='HOTEL_ROOM'? labels.tableOpenings.hotelRoomHeader[language] : labels.tableOpenings.tableHeader[language] }}: {{tableOpening.table.tableNumber}} {{tableOpening.table.familySection!=null ? ' | '+tableOpening.table.familySection : ''}}</h3>
            <div @click="onCloseClick" id="close-modal">
              <font-awesome-icon id="times" icon="times" />
            </div>
          </div>
          <div id="item-description-container">
            <div id="header-details-container" v-if="!hideDetails">
              <h6
                id="item-description"
              >{{labels.tableOpenings.totalCost[language]}} {{(tableOpening.totalPrice + tableOpening.amountTreated).toFixed(2)}}€</h6>
              <h6
                id="item-description"
                v-if="tableOpening.minimumConsumption>0 && tableOpening.totalPrice!=0 && tableOpening.totalPrice<tableOpening.minimumConsumption"
              >{{labels.tableOpenings.minimumConsumption[language]}} {{tableOpening.minimumConsumption.toFixed(2)}}€</h6>
              <div
                class="payed-already-container"
                v-if="tableOpening.currentlyOpen && (tableOpening.amountPayedByCard > 0  || tableOpening.amountPayedByCash > 0) "
              >
                <h6
                  class="payed-already-info"
                  style="margin-right: 6px;"
                >{{labels.tableOpenings.alreadyPaid[language]}} {{(tableOpening.amountPayedByCash + tableOpening.amountPayedByCard).toFixed(2)}}€</h6>|
                <font-awesome-icon
                  class="payed-already-icon-cash"
                  style="margin-right: 4px"
                  icon="money-bill"
                />
                <h6 class="payed-already-info">{{tableOpening.amountPayedByCash.toFixed(2)}}</h6>
                <font-awesome-icon
                  class="payed-already-icon-card"
                  style="margin-left: 10px; margin-right: 4px;"
                  icon="credit-card"
                />
                <h6 class="payed-already-info">{{tableOpening.amountPayedByCard.toFixed(2)}}</h6>
              </div>
              <div
                class="payed-already-container"
                v-if="tableOpening.currentlyOpen && (tableOpening.amountChargedToRoom > 0) "
              >
                <h6
                  class="payed-already-info"
                  style="margin-right: 6px;"
                >{{labels.tableOpenings.chargedToRoom[language]}} {{(tableOpening.amountChargedToRoom).toFixed(2)}}€</h6>
                <font-awesome-icon
                  class="bed-payed-already-icon-cash"
                  style="margin-right: 4px"
                  icon="bed"
                />
              </div>
              <div
                class="payed-already-container"
                v-if="tableOpening.currentlyOpen && (tableOpening.amountTreated > 0) "
              >
                <h6
                  class="payed-already-info"
                  style="margin-right: 6px;"
                >{{labels.tableOpenings.treated[language]}} {{(tableOpening.amountTreated).toFixed(2)}}€</h6>
                <font-awesome-icon class="treated-icon-cash" style="margin-right: 4px" icon="gift" />
              </div>
              <h6
                id="item-description"
                v-if="tableOpening.currentlyOpen && (tableOpening.amountPayedByCard > 0  || tableOpening.amountPayedByCash > 0 || tableOpening.amountChargedToRoom > 0 || tableOpening.amountTreated > 0 )"
              >{{labels.tableOpenings.remainingToBePaid[language]}} {{amountRemainingToBePaidToFixed}}€</h6>

              <div
                class="payed-already-container"
                v-if="!tableOpening.currentlyOpen && tableOpening.amountTreated>0"
              >
                <h6
                  class="payed-already-info"
                  style="margin-right: 6px; font-size:15px;"
                >{{labels.tableOpenings.amountCollected[language]}} {{tableOpening.totalPrice.toFixed(2)}}€</h6>
              </div>

              <div class="payed-already-container" v-if="!tableOpening.currentlyOpen">
                <h6 class="payed-already-info" style="margin-right: 6px;">
                  <font-awesome-icon
                    class="payed-already-icon-cash"
                    style="margin-right: 4px"
                    icon="money-bill"
                  />
                  {{ labels.payment.cash[language] }} {{tableOpening.amountPayedByCash.toFixed(2)}}€
                </h6>

                <h6 class="payed-already-info" style="margin-right: 6px;">
                  <font-awesome-icon
                    class="payed-already-icon-card"
                    style="margin-left: 4px; margin-right: 4px;"
                    icon="credit-card"
                  />
                  {{ labels.payment.card[language] }} {{tableOpening.amountPayedByCard.toFixed(2)}}€
                </h6>
              </div>
              <div
                class="payed-already-container"
                v-if="!tableOpening.currentlyOpen && isHotel && tableOpening.table.type!='HOTEL_ROOM'"
              >
                <h6 class="payed-already-info" style="margin-right: 6px;">
                  <font-awesome-icon
                    class="bed-payed-already-icon-cash"
                    style="margin-right: 4px"
                    icon="bed"
                  />
                  {{labels.tableOpenings.chargedToRoom[language]}} {{tableOpening.amountChargedToRoom.toFixed(2)}}€
                </h6>
              </div>
              <div v-if="!tableOpening.currentlyOpen" class="payed-already-container">
                <h6 class="payed-already-info" style="margin-right: 6px;">
                  <font-awesome-icon
                    class="treated-icon-cash"
                    style="margin-right: 4px"
                    icon="gift"
                  />
                  {{labels.tableOpenings.treated[language]}} {{tableOpening.amountTreated.toFixed(2)}}€
                </h6>
              </div>
            </div>
            <div v-else id="header-details-container">
              <h6
                id="item-description"
              >{{labels.tableOpenings.toBePaidWholeWord[language]}} {{amountRemainingToBePaidToFixed}}€</h6>
            </div>
            <h6 id="table-opening-date">{{tableOpening.openingDate}} | id: {{tableOpening.id}}</h6>
            <div
              id="all-buttons-container"
              v-if="tableOpening.currentlyOpen && !tableOpening.transferred"
            >
              <div id="action-buttons-container" v-if="stateOfModal=='SHOW_ORDERS'">
                <button
                  class="action-button"
                  id="close-table-opening-button"
                  @click="changeModalState('CLOSE_TABLE_OPENING')"
                >{{labels.tableOpenings.closeTable[language]}}</button>
                <button
                  class="action-button"
                  id="transfer-table-opening-button"
                  @click="changeModalState('TRANSFER_ORDERS_OF_TABLE')"
                >{{labels.tableOpenings.transferTable[language]}}</button>
                <button
                  v-if="tableOpening.table.type!='HOTEL_ROOM'"
                  id="new-order-button"
                  @click="addNewOrderToTable()"
                  class="action-button"
                >
                  <font-awesome-icon icon="plus" />
                </button>
                <button
                  class="action-button"
                  :class="{greenEye: hideDetails,
                        greyEye: !hideDetails}"
                  @click="toggleDetails()"
                >
                  <font-awesome-icon v-if="!hideDetails" icon="eye-slash" />
                  <font-awesome-icon v-else icon="eye" />
                </button>
              </div>
              <button
                id="print-whole-order-button"
                class="action-button"
                v-if="stateOfModal=='SHOW_ORDERS'"
                @click="printWholeTableOpening()"
              >
                <clip-loader
                  v-if="printingTableOpening"
                  :loading="printingTableOpening"
                  size="16px"
                  color="#ffffff"
                />
                <font-awesome-icon v-else icon="print" />
              </button>
              <div id="action-buttons-container" v-if="stateOfModal!='SHOW_ORDERS'">
                <button
                  class="action-button"
                  id="back-to-orders-button"
                  @click="changeModalState('SHOW_ORDERS')"
                >
                  <font-awesome-icon icon="arrow-left" style="margin-right: 8px;" />
                  {{labels.tableOpenings.return[language]}}
                </button>
              </div>
            </div>
            <div id="all-buttons-container" v-else-if="!tableOpening.currentlyOpen">
              <div
                id="table-closed-container-info"
              >{{labels.tableOpenings.tableHasClosed[language]}}</div>
              <button
                class="action-button"
                style="margin-bottom: 6px; margin-left:12px;"
                :class="{greenEye: hideDetails,
                        greyEye: !hideDetails}"
                @click="toggleDetails()"
              >
                <font-awesome-icon v-if="!hideDetails" icon="eye-slash" />
                <font-awesome-icon v-else icon="eye" />
              </button>
            </div>
            <div id="all-buttons-container" v-else-if="tableOpening.transferred">
              <div
                id="table-closed-container-info"
              >{{labels.tableOpenings.tableHasBeenTransferred[language]}}</div>
              <button
                class="action-button"
                style="margin-bottom: 6px; margin-left:12px;"
                :class="{greenEye: hideDetails,
                        greyEye: !hideDetails}"
                @click="toggleDetails()"
              >
                <font-awesome-icon v-if="!hideDetails" icon="eye-slash" />
                <font-awesome-icon v-else icon="eye" />
              </button>
            </div>
          </div>
        </div>
        <div v-if="loadingOrders" id="modal-content-container">
          <clip-loader :loading="loadingOrders"></clip-loader>
        </div>
        <div id="modal-content-container" v-else-if="errorWhileLoadingOrders">
          <h5 style="color: red;">{{labels.tableOpenings.somethingWrongTryAgain[language]}}</h5>
        </div>

        <!-------- SHOW ORDERS STATE -------->
        <div v-else-if="stateOfModal=='SHOW_ORDERS'" id="modal-content-container">
          <div
            class="order-content-container"
            v-for="(order, indexOfOrder) in ordersOfSelectedTableOpening"
            :key="order.id"
          >
            <hr v-if="indexOfOrder>0 && !hideDetails" style="margin-top: 18px; margin-bottom: 18px" />
            <div class="order-header-container">
              <div class="order-header-info-container" v-if="!hideDetails">
                <h6 class="order-header-info">
                  {{ labels.delivery.order[language] }}: {{order.dailyOrderNumber}} {{order.pda ? '| PDA':''}} {{order.pda && order.customerName != null && order.customerName.trim().length!=0 ? order.customerName :''}}
                  <font-awesome-icon
                    v-if="!order.pda"
                    style="color:green; margin-left:4px;"
                    icon="user"
                  />
                  <font-awesome-icon
                    v-if="order.pda"
                    style="color: #3d3d3d; margin-left:4px;"
                    icon="mobile-alt"
                  />
                </h6>
                <h6 v-if="order.orderTransfer" class="order-header-info">
                  {{labels.tableOpenings.tranferFromOtherSpot[language]}}
                  <font-awesome-icon style="color: #3d3d3d; margin-left: 2px;" icon="forward" />
                </h6>
                <h6
                  class="order-header-info"
                >{{labels.tableOpenings.totalCost[language]}} {{order.price.toFixed(2)}}€</h6>
                <h6 class="order-header-date">{{order.date}}</h6>
              </div>
              <div class="order-buttons" v-if="!hideDetails">
                <button class="action-button print-just-order-button" @click="printOrder(order)">
                  <clip-loader
                    v-if="printingOrderId == order.id"
                    :loading="printingOrderId == order.id"
                    size="16px"
                    color="#ffffff"
                  />
                  <font-awesome-icon v-else icon="print" />
                </button>
              </div>
            </div>
            <ProductInOrder
              v-for="(orderContent, indexOfOrderContent) in order.products"
              :key="indexOfOrderContent"
              :orderContent="orderContent"
              :language="language"
              :hideDetails="hideDetails"
              :tableOpening="tableOpening"
              @select-order-product="selectOrderProduct"
            ></ProductInOrder>

            <div
              class="order-note-container"
              v-if="order.comment!=null && order.comment.trim().length!=0 && !hideDetails"
            >
              <span class="note-title">{{labels.tableOpenings.noteOfOrders[language]}}</span>
              <span class="order-note-content">{{order.comment}}</span>
            </div>
          </div>
          <div class="order-note-container" v-if="ordersConcatedNote!='' && hideDetails">
            <span class="note-title">{{labels.tableOpenings.noteOfOrders[language]}}</span>
            <span class="order-note-content">{{ordersConcatedNote}}</span>
          </div>
        </div>

        <!-------- CLOSE TABLE STATE -------->
        <div v-else-if="stateOfModal=='CLOSE_TABLE_OPENING'" id="modal-content-container">
          <div id="close-table-opening-container">
            <div
              v-if="amountRemainingToBePaidToFixed !='0.00' &&
                    amountRemainingToBePaidToFixed !='-0.00' "
            >
              <h6 class="total-selected-price-title" style="margin-bottom: 2px !important;">
                {{labels.tableOpenings.remainingToBePaidWholeWord[language]}}
                <span
                  style="color: #007bff;"
                >{{amountRemainingToBePaidToFixed}}€</span>
              </h6>
              <h6 class="payment-type-title">{{labels.payment.paymentType[language]}}</h6>
              <div class="payment-option" style="margin-right: 16px;">
                <input class="input" type="radio" value="CASH" v-model="paymentType" />
                <h6 @click="selectPaymentType('CASH')">{{ labels.payment.cash[language] }}</h6>
                <font-awesome-icon
                  class="payment-type-icon"
                  :class="{ greenColor: paymentType == 'CASH' }"
                  icon="money-bill"
                  @click="selectPaymentType('CASH')"
                />
              </div>
              <div class="payment-option">
                <input class="input" type="radio" value="CARD_TERMINAL" v-model="paymentType" />
                <h6 @click="selectPaymentType('CARD_TERMINAL')">
                  {{ labels.payment.cardAtTheStore[language]}}
                  <font-awesome-icon
                    class="payment-type-icon"
                    :class="{ greenColor: paymentType == 'CARD_TERMINAL' }"
                    icon="credit-card"
                    @click="selectPaymentType('CARD_TERMINAL')"
                  />
                </h6>
              </div>
              <div class="payment-option">
                <input
                  class="input"
                  type="radio"
                  value="CASH_AND_CARD_TERMINAL"
                  v-model="paymentType"
                />
                <h6
                  @click="selectPaymentType('CASH_AND_CARD_TERMINAL')"
                >{{ labels.payment.cashAndCard[language]}}</h6>
              </div>

              <div
                class="payment-option"
                v-if="hotelRooms.length>0 && tableOpening.table.type!='HOTEL_ROOM'"
              >
                <input class="input" type="radio" value="ROOM_CHARGE" v-model="paymentType" />
                <h6
                  @click="selectPaymentType('ROOM_CHARGE')"
                >{{ labels.payment.chargeAtRoom[language]}}</h6>
              </div>

              <div v-if="paymentType == 'CASH_AND_CARD_TERMINAL'">
                <h6
                  class="amount-paid-by-title"
                >{{labels.tableOpenings.amoutToBePaidViaCash[language]}}</h6>
                <input
                  type="number"
                  class="payed-by-input"
                  v-model="amountPayedByCash"
                  @change="typeCashAmount()"
                  @keypress.enter="closeTable()"
                />

                <h6
                  class="amount-paid-by-title"
                >{{labels.tableOpenings.amoutToBePaidViaCard[language]}}</h6>
                <input
                  type="number"
                  class="payed-by-input"
                  style="margin-bottom: 10px;"
                  v-model="amountPayedByCard"
                  @change="typeCardAmount()"
                  @keypress.enter="closeTable()"
                />
              </div>

              <div v-if="paymentType=='ROOM_CHARGE'">
                <h6 class="payment-type-title">{{labels.tableOpenings.chooseChargingRoom[language]}}</h6>
                <select v-model="selectedRoomIdToChargeOnClose" @click="selectRoomToCharge()">
                  <option
                    v-for="hotelRoom in hotelRooms"
                    :key="hotelRoom.id"
                    :value="hotelRoom.id"
                  >{{hotelRoom.tableNumber}}</option>
                </select>
              </div>
            </div>

            <button
              class="action-button"
              id="close-table-opening-button"
              style="margin-right: 0px !important; margin-top: 10px;"
              @click="closeTable()"
            >
              <clip-loader :loading="ongoingModalAction" size="18px" color="#ffffff" />
              {{ongoingModalAction ? '' : labels.tableOpenings.closeTableWholeGreekWord[language]}}
            </button>
            <h6 v-if="errorAtModalAction" id="error-message-container">{{errorMessage}}</h6>
          </div>
        </div>

        <!-------- TRANSFER TABLE STATE -------->
        <div v-else-if="stateOfModal=='TRANSFER_ORDERS_OF_TABLE'" id="modal-content-container">
          <div id="transfer-table-opening-container">
            <h5 v-if="selectedOrderProducts.length > 0" class="total-selected-price-title">
              {{labels.tableOpenings.totalOfSelectedItems[language]}}
              <span
                style="color: #007bff;"
              >{{priceOfSelectedItems.toFixed(2)}}€</span>
            </h5>
            <h6 class="payment-type-title">{{labels.tableOpenings.chooseDepartment[language]}}</h6>
            <select
              v-model="selectedRestaurantIdToTranferTable"
              @change="selectRestaurantToTransfer()"
            >
              <option
                v-for="restaurant in restaurantsWithTableOpenings"
                :key="restaurant.id"
                :value="restaurant.id"
              >{{restaurant.names.translation[language].name }}</option>
            </select>

            <h6
              class="payment-type-title"
              v-if="sections && sections.length>0"
            >{{labels.tableOpenings.areaSection[language]}}</h6>
            <select
              class="no-bottom-margin"
              v-if="sections && sections.length>0"
              v-model="selectedSection"
              @change="onSectionChange()"
            >
              <option value="ALL">Όλα</option>
              <option v-for="(section, index) in sections" :key="index" :value="section">{{section}}</option>
              <option :value="null">Υπόλοιπα</option>
            </select>

            <h6
              class="payment-type-title"
            >{{this.tableOpening.table.type=='HOTEL_ROOM' ? labels.tableOpenings.chooseTransferRoom[language] : labels.tableOpenings.chooseTransferTable[language] }}</h6>
            <select v-model="selectedTableIdToTrasfer" @click="selectTableToTransfer()">
              <option
                v-for="table in allTablesOfRestaurant"
                :key="table.id"
                :value="table.id"
              >{{table.tableNumber}}</option>
            </select>

            <button
              class="action-button"
              id="transfer-table-opening-button"
              style="margin-right: 0px !important; margin-top: 10px;"
              @click="transferTable()"
            >
              <clip-loader :loading="ongoingModalAction" size="18px" color="#ffffff" />
              {{ongoingModalAction ? '' : selectedOrderProducts.length > 0 ? labels.tableOpenings.transferSelectedItems[language] : labels.tableOpenings.transferTableWholeWord[language]}}
            </button>
            <h6 v-if="errorAtModalAction" id="error-message-container">{{errorMessage}}</h6>
          </div>
        </div>

        <!-------- ROOM_CHARGE STATE -------->
        <div v-else-if="stateOfModal=='ROOM_CHARGE'" id="modal-content-container">
          <div id="room-charge-container">
            <h5 class="total-selected-price-title">
              {{labels.tableOpenings.totalOfSelectedItems[language]}}
              <span
                style="color: #007bff;"
              >{{priceOfSelectedItems.toFixed(2)}}€</span>
            </h5>
            <h6 class="payment-type-title">{{labels.tableOpenings.chooseChargingRoom[language]}}</h6>
            <select v-model="selectedRoomIdToCharge" @click="selectRoomToCharge()">
              <option
                v-for="hotelRoom in hotelRooms"
                :key="hotelRoom.id"
                :value="hotelRoom.id"
              >{{hotelRoom.tableNumber}}</option>
            </select>

            <button
              class="action-button"
              id="charge-room-button"
              style="margin-right: 0px !important; margin-top: 10px;"
              @click="chargeToRoom()"
            >
              <clip-loader :loading="ongoingModalAction" size="18px" color="#ffffff" />
              {{ongoingModalAction ? '' : labels.payment.chargeAtRoom[language]}}
            </button>
            <h6 v-if="errorAtModalAction" id="error-message-container">{{errorMessage}}</h6>
          </div>
        </div>
      </div>

      <div
        id="open-table-modal-footer"
        v-if="selectedOrderProducts.length>0 && stateOfModal=='SHOW_ORDERS'"
      >
        <h6
          id="amount-for-partial-payment"
          v-if="!ongoingModalAction"
        >{{labels.tableOpenings.totalOfSelectedItems[language]}} {{priceOfSelectedItems.toFixed(2)}}€</h6>
        <clip-loader
          v-if="ongoingModalAction"
          style="margin-top:8px; margin-bottom:2px;"
          :loading="ongoingModalAction"
          size="18px"
          color="#393939"
        />
        <div id="bottom-buttons-container" v-if="!ongoingModalAction">
          <button
            class="action-button break-2nd"
            id="pay-cash-button"
            @click="payProducts('CASH')"
          >{{labels.tableOpenings.payCash[language]}}</button>
          <button
            class="action-button break-2nd"
            id="pay-card-button"
            @click="payProducts('CARD_TERMINAL')"
          >{{labels.tableOpenings.payCard[language]}}</button>
          <button
            v-if="userCanTreat"
            class="action-button break-2nd"
            id="treat-products-button"
            @click="treatProducts()"
          >{{labels.tableOpenings.treat[language]}}</button>
          <button
            v-if="userCanCancel"
            class="action-button break-2nd"
            id="cancel-products-button"
            @click="cancelProducts()"
          >{{labels.tableOpenings.cancel[language]}}</button>
          <button
            class="action-button break-2nd"
            id="reorder-products-button"
            @click="reOrder()"
          >{{labels.tableOpenings.reOrder[language]}}</button>
          <button
            v-if="hotelRooms && hotelRooms.length>0 && tableOpening.table.type!='HOTEL_ROOM'"
            class="action-button break-2nd"
            id="charge-room-btn"
            @click="changeModalState('ROOM_CHARGE')"
          >Charge room</button>
        </div>
        <h6
          v-if="errorAtModalAction"
          id="error-message-container"
          style="margin-top: 0px !important;"
        >{{errorMessage}}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import StoreApi from "../api/stores";
import OrderApi from "../api/order";
import LogApi from "../api/log";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Button from './Button.vue';
import SubselectionsOfProductAtOrder from "./SubselectionsOfProductAtOrder";
import ProductInOrder from "./ProductInOrder"
import order from '../api/order';
export default {
  name: "OrdersOfOpenTableModal",
  components: {
    ClipLoader,
    ProductInOrder,
    SubselectionsOfProductAtOrder,
    Button
  },
  props: ["tableOpening", "adminAccounts", "isHotel", "sections", "loadingOrders", "ordersOfSelectedTableOpening", "errorWhileLoadingOrders", "ordersConcatedNote", "authToken", "jwt", "restaurantsWithTableOpenings", "hotelRooms"],
  data() {
    return {
      hideDetails: true,
      stateOfModal: 'SHOW_ORDERS',
      paymentType: 'CASH',
      amountPayedByCash: 0,
      amountPayedByCard: 0,
      ongoingModalAction: false,
      errorAtModalAction: false,
      errorMessage: "",
      selectedRestaurantIdToTranferTable: null,
      selectedTableIdToTrasfer: 0,
      selectedRoomIdToCharge: 0,
      selectedRoomIdToChargeOnClose: 0,
      printingTableOpening: false,
      printingOrderId: null,
      selectedOrderProducts: [],
      priceOfSelectedItems: 0,
      selectedSection: 'ALL'
    };
  },
  watch: {
    restaurantsWithTableOpenings(newVal, oldVal) {
      if (newVal.length > 0) {
        this.selectedRestaurantIdToTranferTable = newVal[0].id;
      }
    },

    ordersOfSelectedTableOpening(newVal, oldVal) {
      this.resetModalState();
    }

  },
  computed: {

    isModalOpen() {
      return this.$store.state.itemModal.isOpen;
    },

    nextItemId() {
      return this.$store.getters.getNextItemId;
    },

    storeId() {
      return this.$store.getters.getStoreId;
    },

    serviceName() {
      return this.$store.getters.getServiceName;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    restaurantsWithTheirTables() {
      return this.$store.getters.getRestaurantsWithTheirTables;
    },

    currentGreekDate() {
      return this.$store.getters.getCurrentGreekDate
    },

    userCanCancel() {
      return this.adminAccounts.some(adminAccount => adminAccount.restaurantId == this.tableOpening.restaurant.id && adminAccount.ableToCancel);
    },

    userCanTreat() {
      return this.adminAccounts.some(adminAccount => adminAccount.restaurantId == this.tableOpening.restaurant.id && adminAccount.ableToTreat);
    },

    amountRemainingToBePaidToFixed() {
      return this.amountRemainingToBePaid.toFixed(2);
    },

    amountRemainingToBePaid() {
      if (this.tableOpening.minimumConsumption == 0) {
        return (this.tableOpening.totalPrice - this.tableOpening.amountPayedByCard - this.tableOpening.amountPayedByCash - this.tableOpening.amountChargedToRoom);
      }

      if (this.tableOpening.minimumConsumption > 0 && this.tableOpening.totalPrice == 0) {
        return 0;
      }

      let totalPrice = this.tableOpening.totalPrice <= this.tableOpening.minimumConsumption ? this.tableOpening.minimumConsumption : this.tableOpening.totalPrice;
      return totalPrice - this.tableOpening.amountPayedByCard - this.tableOpening.amountPayedByCash - this.tableOpening.amountChargedToRoom;
    },

    allTablesOfRestaurant() {
      if (this.tableOpening.table.type == 'HOTEL_ROOM' && this.hotelRooms.length > 0) {
        if (this.selectedSection == 'ALL') {
          return this.hotelRooms;
        } else {
          return this.hotelRooms.filter(hotelRoom => hotelRoom.familySection == this.selectedSection);
        }
      } else if (this.restaurantsWithTheirTables.length > 0) {
        let restaurantWithTables = this.restaurantsWithTheirTables.find(restaurant => restaurant.restaurantId == this.selectedRestaurantIdToTranferTable);
        if (this.selectedSection == 'ALL') {
          return restaurantWithTables.tables;
        } else {
          return restaurantWithTables.tables.filter(hotelRoom => hotelRoom.familySection == this.selectedSection);
        }
      } else {
        let restsWithTables = [];
        return restsWithTables;
      }
    }
  },
  methods: {
    onCloseClick: function (event) {
      this.resetModalState();
      this.$store.dispatch("closeTableOrdersModal");
    },

    resetModalState() {
      this.hideDetails = true;
      this.stateOfModal = 'SHOW_ORDERS';
      this.paymentType = 'CASH';
      this.amountPayedByCard = 0;
      this.amountPayedByCard = 0;
      this.ongoingModalAction = false;
      this.errorAtModalAction = false;
      this.errorMessage = "";
      this.selectedTableIdToTrasfer = 0;
      this.selectedRoomIdToCharge = 0;
      this.selectedRoomIdToChargeOnClose = 0;
      this.printingTableOpening = false;
      this.printingOrderId = null;
      this.selectedOrderProducts = [];
      this.priceOfSelectedItems = 0;
      this.selectedSection = 'ALL'
    },

    addNewOrderToTable() {
      this.onCloseClick();
      setTimeout(() => {
        let queryObject = { isPda: true, pdaId: this.$router.currentRoute.params.tableId, tableId: this.tableOpening.table.id };
        this.$router.push({
          name: "menu",
          params: {
            tableId: this.tableOpening.table.id,
            service: this.tableOpening.restaurant.routeName,
          },
          query: queryObject
        });
      }, 80);
    },

    onClickOutside: function (event) {
      if (event.target.id == "modal-container") {
        this.onCloseClick();
      }
    },
    toggleDetails() {
      this.hideDetails = !this.hideDetails;
    },

    changeModalState(state) {
      if (state != 'ROOM_CHARGE' && state != 'TRANSFER_ORDERS_OF_TABLE') {
        this.selectedOrderProducts = [];
        this.priceOfSelectedItems = 0;
      }

      if (state == 'CLOSE_TABLE_OPENING') {
        LogApi.closeTableScreenInitialized(this.tableOpening.id, this.authToken)
      }

      this.stateOfModal = state;
    },

    selectPaymentType(paymentType) {
      this.errorAtModalAction = false;
      this.paymentType = paymentType;
    },

    typeCashAmount() {
      this.errorAtModalAction = false;
      if (this.amountPayedByCash >= this.amountRemainingToBePaid) {
        this.amountPayedByCash = (this.amountRemainingToBePaid).toFixed(2);
        this.amountPayedByCard = 0;
      } else {
        this.amountPayedByCard = (this.amountRemainingToBePaid - this.amountPayedByCash).toFixed(2);
      }
    },

    typeCardAmount() {
      this.errorAtModalAction = false;
      if (this.amountPayedByCard >= this.amountRemainingToBePaid) {
        this.amountPayedByCard = (this.amountRemainingToBePaid).toFixed(2);
        this.amountPayedByCash = 0;
      } else {
        this.amountPayedByCash = (this.amountRemainingToBePaid - this.amountPayedByCard).toFixed(2);
      }
    },

    async selectRestaurantToTransfer() {
      this.errorAtModalAction = false;
      this.errorMessage = "";
      this.selectedTableIdToTrasfer = 0;
      this.selectedSection = 'ALL';
    },

    onSectionChange() {
      this.errorAtModalAction = false;
      this.errorMessage = "";
      if (this.selectedSection != 'ALL') {
        this.selectedTableIdToTrasfer = 0;
      }
    },

    selectTableToTransfer() {
      this.errorAtModalAction = false;
      this.errorMessage = "";
    },

    selectRoomToCharge() {
      this.errorAtModalAction = false;
      this.errorMessage = "";
    },

    async printWholeTableOpening() {
      if (this.ordersOfSelectedTableOpening != null && this.ordersOfSelectedTableOpening.length > 0 && !this.printingTableOpening) {
        this.printingTableOpening = true;
        let orderId = this.ordersOfSelectedTableOpening[0].id;
        let restaurantId = this.ordersOfSelectedTableOpening[0].restaurant.id;
        await OrderApi.sendForPrint(orderId, restaurantId, true, this.authToken, this.jwt);
        setTimeout(() => {
          this.printingTableOpening = false;
        }, 1600);
      }
    },

    selectOrderProduct(orderProduct) {
      let isOrderProductAlreadyInSelected = this.selectedOrderProducts.some(selectedOrderProduct => selectedOrderProduct.id == orderProduct.id);

      if (isOrderProductAlreadyInSelected) {
        if (orderProduct.quantityToBePaid == 0) {
          let orderProductInSelected = this.selectedOrderProducts.find(selectedOrderProduct => selectedOrderProduct.id == orderProduct.id);
          let priceOfProductInSelected = Number(orderProductInSelected.priceOfProduct);
          this.priceOfSelectedItems = this.priceOfSelectedItems - (priceOfProductInSelected * orderProductInSelected.quantityToBePaid);
          this.selectedOrderProducts = this.selectedOrderProducts.filter(selectedOrderProduct => selectedOrderProduct.id != orderProduct.id);
        } else {
          let index = this.selectedOrderProducts.findIndex(selectedOrderProduct => selectedOrderProduct.id === orderProduct.id);
          if (index != -1) {
            let orderProductInSelected = this.selectedOrderProducts[index];
            let priceOfProductInSelected = Number(orderProductInSelected.priceOfProduct);
            let orderProductPrice = Number(orderProduct.priceOfProduct);
            this.priceOfSelectedItems = this.priceOfSelectedItems - (priceOfProductInSelected * orderProductInSelected.quantityToBePaid) + (orderProductPrice * orderProduct.quantityToBePaid);
            this.selectedOrderProducts.splice(index, 1, orderProduct);
          }
        }
      } else {
        let orderProductPrice = Number(orderProduct.priceOfProduct);
        this.priceOfSelectedItems = this.priceOfSelectedItems + (orderProductPrice * orderProduct.quantityToBePaid);
        this.selectedOrderProducts.push(orderProduct);
      }
    },

    async payProducts(paymentType) {

      if (confirm(`Είστε σίγουρος για την πληρωμή των επιλεγμένων προϊόντων με ${paymentType == 'CASH' ? 'μετρητά' : 'κάρτα'};`) == true) {
        this.ongoingModalAction = true;
        this.errorAtModalAction = false;
        this.errorMessage = "";

        let payload = {
          tableOpeningId: this.tableOpening.id,
          orderProductsForPayment: this.selectedOrderProducts,
          paymentType: paymentType
        }

        let restaurantIds = this.restaurantsWithTableOpenings.map(restaurant => restaurant.id);
        let response = await StoreApi.payPartialBeforeClose(restaurantIds, payload, this.authToken, this.jwt);
        if (response.error) {
          this.errorAtModalAction = true;
          this.ongoingModalAction = false;
          this.errorMessage = 'Κάτι πήγε λάθος, δοκιμάστε ξανά.'
        } else {
          this.$emit("update-selected-tableOpening", response);
          this.selectedOrderProducts = [];
          this.priceOfSelectedItems = 0;
          this.errorAtModalAction = false;
          this.ongoingModalAction = false;
        }
      }
    },

    async chargeToRoom() {
      this.errorMessage = "";
      if (this.selectedRoomIdToCharge == 0 || this.selectedRoomIdToCharge == null) {
        this.errorAtModalAction = true;
        this.ongoingModalAction = false;
        this.errorMessage = 'Επιλέξτε δωμάτιο που θα γίνει η χρέωση.'
        return;
      }

      if (confirm(`Είστε σίγουρος για την χρέωση στο δωμάτιο των επιλεγμένων προϊόντων`) == true) {
        this.ongoingModalAction = true;
        this.errorAtModalAction = false;
        this.errorMessage = "";

        let payload = {
          tableOpeningId: this.tableOpening.id,
          orderProductsForPayment: this.selectedOrderProducts,
          paymentType: "ROOM_CHARGE",
          chargingRoomId: this.selectedRoomIdToCharge
        }

        let restaurantIds = this.restaurantsWithTableOpenings.map(restaurant => restaurant.id);
        let response = await StoreApi.chargeToRoom(restaurantIds, payload, this.authToken, this.jwt);
        if (response.error) {
          this.errorAtModalAction = true;
          this.ongoingModalAction = false;
          this.errorMessage = 'Κάτι πήγε λάθος, δοκιμάστε ξανά.'
        } else {
          this.changeModalState('SHOW_ORDERS');
          this.$emit("update-selected-tableOpening", response);
          this.selectedOrderProducts = [];
          this.priceOfSelectedItems = 0;
          this.errorAtModalAction = false;
          this.ongoingModalAction = false;
        }
      }
    },

    async cancelProducts() {
      if (confirm(`Είστε σίγουρος για την ακύρωση των επιλεγμένων προϊόντων;`) == true) {
        this.ongoingModalAction = true;
        this.errorAtModalAction = false;
        this.errorMessage = "";

        let payload = {
          tableOpeningId: this.tableOpening.id,
          orderProductsForPayment: this.selectedOrderProducts,
        }

        let restaurantIds = this.restaurantsWithTableOpenings.map(restaurant => restaurant.id);
        let response = await StoreApi.cancelProducts(restaurantIds, payload, this.authToken, this.jwt);
        if (response.error) {
          this.errorAtModalAction = true;
          this.ongoingModalAction = false;
          this.errorMessage = 'Κάτι πήγε λάθος, δοκιμάστε ξανά.'
        } else {
          this.$emit("update-selected-tableOpening", response);
          this.selectedOrderProducts = [];
          this.priceOfSelectedItems = 0;
          this.errorAtModalAction = false;
          this.ongoingModalAction = false;
        }
      }
    },

    async treatProducts() {
      if (confirm(`Είστε σίγουρος πως θέλετε να κεράσετε τα επιλεγμένα προϊόντα;`) == true) {
        this.ongoingModalAction = true;
        this.errorAtModalAction = false;
        this.errorMessage = "";

        let payload = {
          tableOpeningId: this.tableOpening.id,
          orderProductsForPayment: this.selectedOrderProducts,
        }

        let restaurantIds = this.restaurantsWithTableOpenings.map(restaurant => restaurant.id);
        let response = await StoreApi.treatProducts(restaurantIds, payload, this.authToken, this.jwt);
        if (response.error) {
          this.errorAtModalAction = true;
          this.ongoingModalAction = false;
          this.errorMessage = 'Κάτι πήγε λάθος, δοκιμάστε ξανά.'
        } else {
          this.$emit("update-selected-tableOpening", response);
          this.selectedOrderProducts = [];
          this.priceOfSelectedItems = 0;
          this.errorAtModalAction = false;
          this.ongoingModalAction = false;
        }
      }
    },

    async reOrder() {
      if (confirm(`Είστε σίγουρος πως θέλετε να παραγγείλετε ξανά τα επιλεγμένα προϊόντα;`) == true) {
        this.ongoingModalAction = true;
        this.errorAtModalAction = false;
        this.errorMessage = "";

        let payload = {
          tableOpeningId: this.tableOpening.id,
          orderProductsForPayment: this.selectedOrderProducts,
        }

        let restaurantIds = this.restaurantsWithTableOpenings.map(restaurant => restaurant.id);
        let response = await StoreApi.reOrder(restaurantIds, payload, this.authToken, this.jwt);
        if (response.error) {
          this.errorAtModalAction = true;
          this.ongoingModalAction = false;
          this.errorMessage = 'Κάτι πήγε λάθος, δοκιμάστε ξανά.'
        } else {
          this.$emit("update-selected-tableOpening", response);
          this.selectedOrderProducts = [];
          this.priceOfSelectedItems = 0;
          this.errorAtModalAction = false;
          this.ongoingModalAction = false;
        }
      }
    },

    async printOrder(order) {
      if (this.printingOrderId != order.id) {
        this.printingOrderId = order.id;
        let orderId = order.id;
        let restaurantId = order.restaurant.id;
        await OrderApi.sendForPrint(orderId, restaurantId, false, this.authToken, this.jwt);
        setTimeout(() => {
          this.printingOrderId = null;
        }, 1600);
      }
    },

    async closeTable() {
      LogApi.closeTableButtonPressed(this.tableOpening.id, this.paymentType, this.authToken);
      LogApi.closeTableConfirmed(this.tableOpening.id, this.authToken);
      this.ongoingModalAction = true;
      this.errorAtModalAction = false;
      this.errorMessage = "";

      if (this.paymentType == 'CASH_AND_CARD_TERMINAL' && this.amountRemainingToBePaid != (Number(this.amountPayedByCard) + Number(this.amountPayedByCash))) {
        this.errorAtModalAction = true;
        this.ongoingModalAction = false;
        this.errorMessage = 'Το ποσό εξόφλησης μετρητών μαζί με το ποσό εξόφλησης κάρτας, δεν αθροίζουν το συνολικό ποσό της παραγγελίας.'
        LogApi.closeTableNotCorrectlyInputsFilled(this.tableOpening.id, "AMOUNT_CARD_AND_AMOUNT_CASH_NOT_SUMMING_TOTAL", this.authToken);
        return;
      }

      if (this.paymentType == 'ROOM_CHARGE' && this.selectedRoomIdToChargeOnClose == 0) {
        this.errorAtModalAction = true;
        this.ongoingModalAction = false;
        this.errorMessage = 'Επιλέξτε δωμάτιο χρέωσης'
        LogApi.closeTableNotCorrectlyInputsFilled(this.tableOpening.id, "ROOM_CHARGE_ROOM_NOT_SELECTED", this.authToken);
        return;
      }

      if (this.paymentType == 'ROOM_CHARGE') {
        await this.chargeToRoomAndCloseTable();
        this.ongoingModalAction = false;
      } else {
        let closeTableOpeningRequest = {
          tableOpeningId: this.tableOpening.id,
          paymentType: this.paymentType,
          amountPayedByCash: this.amountPayedByCash,
          amountPayedByCard: this.amountPayedByCard
        }

        let restaurantId = this.tableOpening.restaurant.id;
        let response = await StoreApi.closeTableOpeningsForRestaurant(restaurantId, closeTableOpeningRequest, this.authToken, this.jwt);
        if (response.error) {
          LogApi.closeTableError(this.tableOpening.id, this.authToken);
          this.errorAtModalAction = true;
          this.errorMessage = 'Κάτι πήγε λάθος, δοκιμάστε ξανά.'
        } else {
          LogApi.tableClosedSuccesfully(this.tableOpening.id, this.authToken);
          this.$emit("set-selected-tableOpening", response);
          this.stateOfModal = 'SHOW_ORDERS'
        }
        this.ongoingModalAction = false;
      }


    },

    async chargeToRoomAndCloseTable() {
      let orderProductsToChargeToRoom = []
      this.ordersOfSelectedTableOpening.forEach(async order => {
        for (let orderProduct of order.products) {
          let quantityToBeChargedToRoom = orderProduct.quantity - orderProduct.quantityCanceled - orderProduct.quantityPayedBeforeClose - orderProduct.quantityChargedToRoom - orderProduct.quantityTreated;
          if (quantityToBeChargedToRoom > 0) {
            let orderProductToBeChargedToRoom = {
              id: orderProduct.id,
              quantityToBePaid: quantityToBeChargedToRoom
            }
            orderProductsToChargeToRoom.push(orderProductToBeChargedToRoom);
          }
        }
      })

      let payload = {
        tableOpeningId: this.tableOpening.id,
        orderProductsForPayment: orderProductsToChargeToRoom,
        paymentType: "ROOM_CHARGE",
        chargingRoomId: this.selectedRoomIdToChargeOnClose
      }
      let restaurantIds = this.restaurantsWithTableOpenings.map(restaurant => restaurant.id);
      await StoreApi.chargeToRoom(restaurantIds, payload, this.authToken, this.jwt);

      let closeTableOpeningRequest = {
        tableOpeningId: this.tableOpening.id,
        paymentType: 'ROOM_CHARGE',
        amountPayedByCash: 0,
        amountPayedByCard: 0
      }
      let restaurantId = this.tableOpening.restaurant.id;
      let response = await StoreApi.closeTableOpeningsForRestaurant(restaurantId, closeTableOpeningRequest, this.authToken, this.jwt);
      if (response.error) {
        this.errorAtModalAction = true;
        this.errorMessage = 'Κάτι πήγε λάθος, δοκιμάστε ξανά.'
      } else {
        this.$emit("set-selected-tableOpening", response);
        this.stateOfModal = 'SHOW_ORDERS'
      }
    },

    async transferTable() {
      this.ongoingModalAction = true;
      this.errorAtModalAction = false;
      this.errorMessage = "";
      if (this.selectedTableIdToTrasfer == 0 || this.selectedTableIdToTrasfer == null) {
        this.errorAtModalAction = true;
        this.ongoingModalAction = false;
        this.errorMessage = 'Επιλέξτε τραπέζι μεταφοράς.'
        return;
      }

      if (this.selectedTableIdToTrasfer == this.tableOpening.table.id && this.tableOpening.restaurant.id == this.selectedRestaurantIdToTranferTable) {
        this.errorAtModalAction = true;
        this.ongoingModalAction = false;
        this.errorMessage = 'Το τραπέζι μεταφοράς που επιλέξατε είναι ίδιο με το ήδη υπάρχων. Επιλέξτε κάποιο άλλο για να γίνει η μεταφορά.'
        return;
      }

      if (this.selectedOrderProducts.length > 0) {
        await this.partialTransfer();
        return;
      }

      let response = await StoreApi.transferTableOpening(this.tableOpening.id, this.selectedTableIdToTrasfer, this.tableOpening.restaurant.id, this.selectedRestaurantIdToTranferTable, this.authToken, this.jwt);
      if (response.error) {
        this.errorAtModalAction = true;
        this.ongoingModalAction = false;
        this.errorMessage = 'Κάτι πήγε λάθος, δοκιμάστε ξανά.'
      } else {
        this.ongoingModalAction = false;
        this.ongoingModalAction = false;
        this.tableOpening.transferred = true;
        this.stateOfModal = 'SHOW_ORDERS'
        setTimeout(() => {
          alert("Το τραπέζι μεταφέρθηκε");
          this.onCloseClick();
          this.$emit("reload-tables-screen");
        }, 350);
      }
    },

    async partialTransfer() {
      this.ongoingModalAction = true;
      this.errorAtModalAction = false;
      this.errorMessage = "";

      let payload = {
        tableOpeningId: this.tableOpening.id,
        orderProductsForPayment: this.selectedOrderProducts,
        tranferTableId: this.selectedTableIdToTrasfer
      }

      let restaurantIds = this.restaurantsWithTableOpenings.map(restaurant => restaurant.id);
      let response = await StoreApi.partialTransfer(restaurantIds, payload, this.authToken, this.jwt);
      console.log(response);
      if (response.error) {
        this.errorAtModalAction = true;
        this.ongoingModalAction = false;
        this.errorMessage = 'Κάτι πήγε λάθος, δοκιμάστε ξανά.'
      } else {
        this.changeModalState('SHOW_ORDERS');
        this.$emit("reload-tables-screen");
        this.$emit("update-selected-tableOpening", response);
        this.selectedOrderProducts = [];
        this.priceOfSelectedItems = 0;
        this.errorAtModalAction = false;
        this.ongoingModalAction = false;
      }

    },

  },
};
</script>

<style scoped>
#modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 205 !important;
}

.modal-container-top-screen {
  margin-top: 1vh;
  align-items: flex-start;
}

.modal-container-centered {
  align-items: center;
}

#modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  min-width: 200px;
  min-height: 450px;
  max-height: 95%;
  background: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  overflow-y: scroll;

  opacity: 1;
  border-radius: 12px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  z-index: 202 !important;
}

#header-and-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#modal::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#close-modal {
  position: sticky;
  top: 2%;
  left: 93%;
  background: white;
  border-radius: 100%;
  min-height: 4.5vh;
  height: 40% !important;
  width: 4.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

.lower-cause-no-image {
  top: 3% !important;
}

#wide-image {
  margin-top: -4.5vh;
  max-height: 65vh;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#modal-content-container {
  margin-top: 2.5vh;
  margin-bottom: 40px;
}

.order-content-container {
  display: flex;
  flex-direction: column;
}

#close-table-opening-container {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
}

#transfer-table-opening-container {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
}

#room-charge-container {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
}

.order-header-container {
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-header-info-container {
  display: flex;
  flex-direction: column;
}

.order-header-info {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
  color: #393939;
}

.order-header-date {
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 0px;
  color: #424242;
}

.total-selected-price-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-right: 0px;
}

.payment-type-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-right: 0px;
}

.order-note-container {
  padding-left: 8px;
}

.note-title {
  font-size: 15px;
  font-weight: 800;
  margin-right: 6px;
}

.payment-option {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.payment-type-icon {
  margin-left: 6px;
  margin-top: 3px;
  color: rgb(95, 95, 95);
}

.greenColor {
  color: #198b4a !important;
}

.payment-option > h6 {
  font-size: 14px;
}

.input {
  accent-color: #198b4a;
  margin-bottom: 6px;
  margin-right: 5px;
}

.order-note-content {
  font-size: 15px;
  font-weight: 500;
}

#error-message-container {
  font-size: 14px;
  color: red;
  margin-top: 8px;
  margin-left: 4px;
}

#open-table-modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-shrink: 0;
  width: 100%;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 2px 4px rgba(0, 0, 0, 0.36);
  position: sticky;
  top: 0;
}

#open-table-modal-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: white;
}

#amount-for-partial-payment {
  color: #393939;
  margin-bottom: 0px;
  margin-top: 7px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 700;
}

#bottom-buttons-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0 4px;
  justify-content: space-between;
  padding-bottom: 6px;
  padding-right: 4px;
  padding-left: 4px;
  width: 100%;
}

#table-and-close-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
}

#item-description-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  margin: 0% 4.5% 2% 4%;
}

#header-details-container {
  display: flex;
  flex-direction: column;
}

#all-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#action-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
}

.action-button {
  padding: 4px 8px 4px 8px;
  border-radius: 6px;
  cursor: pointer;
}

.order-buttons {
  display: flex;
  flex-direction: column;
}

#close-table-opening-button {
  color: white;
  background-color: #007bff;
  margin-right: 10px;
}

#pay-cash-button {
  color: white;
  background-color: #5cb85c;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

#pay-card-button {
  color: white;
  background-color: #17a2b8;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

#cancel-products-button {
  color: white;
  background-color: #d9534f;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

#reorder-products-button {
  color: white;
  background-color: #6c757d;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

#treat-products-button {
  color: white;
  background-color: #6f2c91;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

#charge-room-btn {
  color: white;
  background-color: #ffc107;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

.break-2nd {
  width: 48%;
  flex-grow: 1;
}

#back-to-orders-button {
  color: white;
  background-color: #5a6268;
}

#transfer-table-opening-button {
  color: #212529;
  background-color: #ffc107;
  margin-right: 10px;
}

#charge-room-button {
  color: #212529;
  background-color: #ffc107;
  margin-right: 12px;
}

#table-closed-container-info {
  padding: 4px 8px 4px 8px;
  border-radius: 6px;
  color: #212529;
  background-color: #ffc107;
  opacity: 0.65;
  width: 100%;
  margin-bottom: 6px;
  text-align: center;
}

.greenEye {
  color: #198b4a;
  background-color: #ffffff;
  border: 1px solid;
}

.greyEye {
  color: #414141;
  background-color: #ffffff;
  border: 1px solid;
}

#hide-details-button {
  color: #212529;
  background-color: #ffc107;
}

#print-whole-order-button {
  color: white;
  background-color: #198b4a;
  margin-bottom: 6px;
  margin-left: 4px;
}

#new-order-button {
  width: 40px;
  color: white;
  background-color: #198b4a;
  margin-right: 10px;
}

.amount-paid-by-title {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
}

.payed-by-input {
  background-color: white;
  border: 1px solid;
  border-color: rgb(183, 183, 183);
  padding: 2px 0px 2px 4px;
  border-radius: 4px;
  width: 250px;
}

.execute-order-button {
  color: green;
  border: 1px solid;
}

.print-just-order-button {
  color: #212529;
  background-color: #ababab;
  margin-right: 6px;
}

.payed-already-container {
  display: flex;
  align-items: center;
}

.payed-already-info {
  color: #414141;
  margin-bottom: 0px;
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 14px;
}

.payed-already-icon-card {
  font-size: 15px;
  margin-left: 5px;
  color: #17a2b8;
  margin-bottom: 0px;
}

.payed-already-icon-cash {
  font-size: 15px;
  margin-left: 5px;
  color: #198b4a;
  margin-bottom: 0px;
}

.bed-payed-already-icon-cash {
  font-size: 15px;
  margin-left: 5px;
  color: #003340;
  margin-bottom: 0px;
}

.treated-icon-cash {
  font-size: 15px;
  margin-left: 5px;
  color: #6f2c91;
  margin-bottom: 0px;
}

#item-description {
  color: #414141;
  margin-bottom: 0px;
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 16px;
}

select {
  width: 80%;
  display: flex;
  justify-self: center;
  -webkit-appearance: auto !important;
  box-shadow: inset 0px 0px 2px rgba(116, 135, 150, 0.2),
    inset 1px 1px 2px rgba(116, 135, 150, 0.36);
  border-radius: 6px;
  margin-bottom: 0.5rem;
  padding: 4px;
  color: #198b4a !important;
  font-weight: bold;
  background: #f7f7f7;
}
@media screen and (max-width: 800px) {
  select {
    font-size: 14.5px;
  }
}

option {
  color: #198b4a !important;
  font-weight: 600;
}

#table-opening-date {
  margin-top: 4px;
  color: #585858;
  font-size: 12px;
}

@media only screen and (max-width: 1285px) {
  .no-image-width {
    width: 90%;
  }
}

@media screen and (max-width: 1285px) {
  #modal {
    width: 98vw;
    height: 98vh;
    min-height: 97%;
  }

  .modal-bigger {
    max-height: 92% !important;
  }

  .modal-smaller {
    max-height: 80% !important;
  }

  #wide-image {
    max-height: 45vh;
  }

  #close-modal {
    width: 4.5vh;
    min-height: 4.5vh;
    top: 1%;
    margin-right: 8px;
  }

  #wide-image {
    margin-top: -4.5vh;
  }

  #plus,
  #minus {
    width: 23px;
    height: 23px;
  }

  #plus,
  #minus,
  #current-quantity,
  h4 {
    font-size: 14px !important;
  }

  #modal-add-to-cart {
    font-size: 14px;
    height: 5.5vh;
    max-height: 53px;
    flex: 0.9;
  }

  #current-quantity {
    margin: 0vw 3vw 0 3vw;
  }

  #item-description-container {
    width: 95%;
    margin: 0% 8px 1% 8px;
  }

  #item-name {
    margin: 10px 8px 0 8px;
  }

  #show-more {
    margin-top: 1%;
  }
}

@media screen and (max-width: 753px) {
  #item-name {
    font-size: 18px;
  }

  #item-description {
    font-size: 15px;
  }

  #price > h5 {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  #item-name {
    font-size: 16px;
    margin-right: 13.5%;
  }

  #show-more {
    font-size: 11px;
  }

  #plus,
  #minus {
    width: 19px;
    height: 19px;
  }

  #plus,
  #minus,
  #current-quantity,
  h4 {
    font-size: 12px !important;
  }

  #price > h5 {
    font-size: 12px;
  }

  #regular-price > h5 {
    font-size: 12px;
  }

  #membership-price > h5 {
    font-size: 12px;
  }

  #modal-add-to-cart {
    font-size: 12px;
    flex: 0.7;
  }
}

@media (max-width: 315px) {
  #modal-add-to-cart {
    font-size: 12px;
    flex: 0.72;
  }
}

@media screen and (max-width: 350px) {
  #close-modal {
    min-height: 3.5vh;
    width: 3.5vh;
    margin-right: 5%;
  }

  #times {
    font-size: 0.85rem;
  }

  #wide-image {
    margin-top: -3.5vh;
  }
}

@media (max-width: 300px) {
  #item-name {
    font-size: 15px;
  }

  #item-description {
    font-size: 14px;
  }

  #show-more {
    font-size: 10px;
  }

  #price > h5 {
    font-size: 10px;
  }

  #regular-price > h5 {
    font-size: 10px;
  }

  #membership-price > h5 {
    font-size: 10px;
  }

  select {
    font-size: 12px;
  }
}

@media screen and (max-height: 300px) {
  #modal {
    min-height: 100%;
  }
}
</style>



